import Swal from "sweetalert2";
import { resetErrorAction } from "../redux/globalActions/globalAction";
import { useDispatch } from "react-redux";

const ErrorMsg = ({ message }) => {
  const dispatch = useDispatch();
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
    confirmButtonText: "ok",
  });
  dispatch(resetErrorAction());
};

// const ErrorMsg = ({ message }) => {
//   Swal.fire({
//     icon: "error",
//     title: "Oops...",
//     text: message,
//     confirmButtonText: "ok",
//   }).then((isConfirm) => {
//     if (isConfirm) {
//       window.location.reload();
//     }
//   });
// };

export default ErrorMsg;
