import React from "react";
import "../../src/palette-bsb.css";
// import Footer from "./Footer";
// import Home from "./Home";
import "../palette-bsb.css";
export const Navbar = () => {
  return (
    <div>
      <header id="header">
        {/* <!-- Navbar 1 - Bootstrap Brain Component --> */}

        <nav
          class="navbar navbar-expand-lg bsb-navbar bsb-navbar-hover bsb-navbar-caret bsb-tpl-navbar-float bsb-tpl-navbar-sticky"
          data-bs-theme="dark"
          data-bsb-stuck-bg="bg-primary"
          data-bsb-stuck-theme="dark"
        >
          <div class="container  mt-5">
            <a class="navbar-brand" href="/">
              <img
                src="../../../images/swastik abroad consultants logo_page-0001.jpg"
                className="bsb-tpl-logo"
                alt=""
              />
            </a>

            <button
              class="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
            <div
              class="offcanvas offcanvas-end bg-primary"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  Menu
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body col-xl-12 ">
                <ul
                  id="bsb-tpl-navbar "
                  class="navbar-nav justify-content-end flex-grow-1"
                >
                  <li class="nav-item mr-5">
                    <a class="nav-link active" aria-current="page" href="/">
                      Home
                    </a>
                  </li>
                  <li class="nav-item dropdown mr-4" data-bs-theme="light">
                    <a
                      class="nav-link text-white dropdown-toggle show"
                      href="#"
                      id="blogDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                    
                    >
                      Our Visa
                    </a>
                    <ul
                      class="dropdown-menu border-0 shadow bsb-zoomIn show mt-0"
                      aria-labelledby="blogDropdown"
                    >
                      <li>
                        <a class="dropdown-item" href="/studyVisa">
                          Study Visa
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/touristVisa">
                          Tourist Visa
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/spouseVisa">
                          Spouse Visa
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/immigrationVisa">
                          Immigration
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item mr-2">
                    <a class="nav-link" href="/about">
                      About
                    </a>
                  </li>
                  <li class="nav-item mr-2 ">
                    <a class="nav-link" href="/contactUS">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
