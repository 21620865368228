import React, { useState } from "react";
import "../css/contactUs.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { createUserDataAction } from "../redux/slices/UserdataSlice";
import ErrorMsg from "../errorMsg/ErrorMsg";
import SuccessMsg from "../successMsg/SuccessMsg";

//animated components for react-select
const animatedComponents = makeAnimated();
const ContactUs = () => {
  const dispatch = useDispatch();

  //visa data
  const visa = [
    "Study Abroad Visa,",
    "Spouse Visa,",
    "Tourist Visa,",
    "Imigration",
  ];
  const [visaOption, setVisaOption] = useState([]);
  const handleVisaChange = (visa) => {
    setVisaOption(visa);
  };

  //converted Visa options
  const visaOptionsCoverted = visa?.map((visa) => {
    return {
      value: visa,
      label: visa,
    };
  });

  //form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    visaType: "",
    email: "",
    message: "",
  });

  //onChange
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //useSelector
  const { userSingleData, error, isAdded } = useSelector(
    (state) => state?.userData
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // console.log(visaOption);

    dispatch(
      createUserDataAction({
        ...formData,
        visaType: visaOption?.map((size) => size.label),
      })
    );

    //reset form data
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      visaType: "",
      message: "",
    });
  };
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      Contact Us
                    </h1>
                    <div class="row">
                      <div class="col-12 col-xxl-8">
                        <p class="fs-5 mb-0 text-secondary">
                          Communication is the key to success, and we're always
                          here to answer your questions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../../../images/frontEndImg/headerimgs/contact-img-1.webp"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Section - Services -->
    <!-- Service 4 - Bootstrap Brain Component --> */}

        {/* <!-- Section - Contact Form -->
    <!-- Contact 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 p-0" style={{ marginTop: "600px" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.459238344533!2d76.735403575373!3d30.733599274583884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed005b1f8fbb%3A0xf6aa3573946dfaae!2sSwastik%20abroad%20consultants!5e0!3m2!1sen!2sin!4v1708345709647!5m2!1sen!2sin"
                  style={{
                    width: "800px",
                    height: "600px",
                    border: "0px",
                    allowfullscreen: "",
                    loading: "lazy",
                    referrerpolicy: "no-referrer-when-downgrade",
                  }}
                ></iframe>
              </div>
              <div class="col-12 col-md-6 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xxl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    {error && <ErrorMsg message={error?.message} />}
                    {isAdded && (
                      // <SuccessMsg message={userSingleData?.message} />
                      <SuccessMsg message="You Have Been Successfully Registered" />
                    )}
                    <h2 class="display-4 fw-bold mb-4">Get in Touch</h2>
                    <p class="fs-5 mb-5 text-secondary">
                      We're always on the lookout to work with new clients. If
                      you want to work with us, please write to us using the
                      form below.
                    </p>
                    <div
                      class="border-accent text-bg-primary border rounded shadow-sm overflow-hidden"
                      data-bs-theme="light"
                    >
                      <form onSubmit={handleOnSubmit}>
                        <div class="row gy-4 gy-xl-5 p-4 p-xl-5">
                          <div class="col-12">
                            <label for="fullname" class="form-label">
                              First Name <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="fullname"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div class="col-12">
                            <label for="fullname" class="form-label">
                              Last Name <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="fullname"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleOnChange}
                              required
                            />
                          </div>

                          <div class="col-12 col-md-12 ">
                            <label for="phone" class="form-label">
                              Phone Number <span class="text-danger">*</span>
                            </label>
                            <div class="input-group">
                              <span class="input-group-text">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-telephone"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                              </span>
                              <input
                                type="tel"
                                class="form-control"
                                id="phone"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 mt-5">
                            <div className="form-group">
                              <label className="">
                                Visa Type <span class="text-danger">*</span>
                              </label>
                              <Select
                                components={animatedComponents}
                                isMulti
                                name="visaType"
                                options={visaOptionsCoverted}
                                className="basic-multi-select text-dark mt-2"
                                classNamePrefix="select"
                                isClearable={true}
                                isLoading={false}
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                onChange={(item) => handleVisaChange(item)}
                                required
                              />
                            </div>

                            <div class="col-12 col-md-12 mt-5">
                              <label for="email" class="form-label">
                                Email <span class="text-danger">*</span>
                              </label>
                              <div class="input-group">
                                <span class="input-group-text">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-envelope"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                  </svg>
                                </span>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleOnChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <label for="message" class="form-label">
                              Message
                            </label>
                            <textarea
                              class="form-control"
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleOnChange}
                              rows="3"
                              required
                            ></textarea>
                          </div>
                          <div class="col-12">
                            <div class="d-grid">
                              <button
                                class="btn btn-accent btn-lg"
                                type="submit"
                              >
                                SUBMIT
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;
