import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetErrorAction, resetSuccessAction } from "../globalActions/globalAction";
import axios from "axios";
import baseURL from "../../utils/baseURL";
//initialState
const initialState = {
  loading: false,
  error: null,
  users: [],
  user: null,
  profile: {},
  userAuth: {
    loading: false,
    error: null,
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

//login action
export const loginAdminAction = createAsyncThunk(
  "admin/login",
  async ( payload , { rejectWithValue, getState, dispatch }) => {
    //console.log({email,password});
    try {
      const {email,password} = payload
      const { data } = await axios.post(`${baseURL}/admin/login`, {
        email,
        password,
      });
      // save the user into localStorage
      localStorage.setItem("userInfo", JSON.stringify(data));
      return data;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const adminSlice = createSlice({
  name: "Admins",

  //handle actions
  //login
  initialState,
  extraReducers: (builder) => {
    //login handle actions
    builder.addCase(loginAdminAction.pending, (state, action) => {
      state.userAuth.loading = true;
    });
    builder.addCase(loginAdminAction.fulfilled, (state, action) => {
      state.userAuth.userInfo = action.payload;
      state.userAuth.loading = false;
    });
    builder.addCase(loginAdminAction.rejected, (state, action) => {
      state.userAuth.error = action.payload;
      state.userAuth.loading = false;
    });

      // reset success
      builder.addCase(resetSuccessAction.pending, (state, action) => {
        state.isAdded = false;
      });
      //reset error Action
      builder.addCase(resetErrorAction.pending, (state, action) => {
        state.error = null;
      });
  },
});

// generate the reducer

const adminLoginReducer = adminSlice.reducer;

export default adminLoginReducer;
