import React from "react";
import "../../css/touristvisa.css";

const Studyvisa = () => {
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      Welcome to Swastik Abroad Consultants
                    </h1>
                    <h2> Study Visa Consulting Services</h2>
                    <div class="row">
                      <div class="col-12 col-xxl-8"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../images/study visa/studyvisa.jpg"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      <section>
        <div className="imgss">
          <div className="threeimgs">
            <div className="firstimg">
              <img src="../images/study visa/uk.jpg" />
              <p className="study">Study Abroad</p>
              <h3>UK</h3>
              <p className="why">
                Why study in the UK UK is<br></br> a favorite destination for
                <br></br> higher studies for students<br></br> from around the
                globe and <br></br>is a haven to three of the top <br></br>10
                universities in the world. <br></br>The UK has retained its
                position as<br></br> a popular destination among international{" "}
                <br></br>students owing to its long-standing<br></br> tradition
                of providing quality education.
              </p>
            </div>
            <div className="secondimg">
              <img src="../images/study visa/Canada.jpg" />
              <p className="study">Study Abroad</p>
              <h3>Canada</h3>
              <p className="why">
                Canada is a hugely popular <br></br>choice as an overseas
                <br></br> study destination for a<br></br> host of reasons. From{" "}
                <br></br>
                secure environment to first-rate<br></br> education system,
                abundant
                <br></br>
                opportunities to buzzing city life<br></br> and picturesque
                locations,<br></br> Canada offers everything you<br></br> could
                possibly want from life. <br></br>QUICK FACTS Capital : Ottawa
                Number of <br></br>universities : 98 Temperature : subarctic in
                […]
              </p>
            </div>
            <div className="thirdimg">
              <img src="../images/study visa/Australia.jpg" />
              <p className="study">Study Abroad</p>
              <h3>Australia</h3>
              <p className="why">
                Why study in the Australia <br></br>Australia is quickly
                <br></br> becoming a preferred <br></br>destination regarding{" "}
                <br></br>
                education among
              </p>
            </div>
          </div>
          <div className="fourthimg">
            <img src="../images/study visa/Japan.jpg" />
            <p className="study">Study Abroad</p>
            <h3>Japan</h3>
            <p className="why">
              Japan is neither a large<br></br> country nor blessed with{" "}
              <br></br>
              abundant natural resources,<br></br> but Japan has established a
              firm position<br></br> in the world as an advanced nation<br></br>{" "}
              with high economic power. <br></br>The high level of Japan’s
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="content">
          <h2 className="whatt">What is a Student Visa?</h2>
          <p className="what">
            A Student Visa is a type of visa that allows you to enter a foreign
            country and study at an educational institution. It's what you need
            to be able to enrol in a school or university in another country.
            <br></br>
            Think of it as a permit to study abroad. Without it, you won't be
            able to attend class or live in the country. Now, different
            countries have different types of Student Visas, <br></br>and they
            also have different requirements and fees. Therefore, it's important
            to research the specific details of the country you're planning to
            study in.
          </p>
          <h2 className="whatt">Student Visa types</h2>
          <p className="what">
            When it comes to Student Visas, it's important to know that
            different countries offer different types of visas. Understanding
            the types of Student Visas available will help you make the<br></br>{" "}
            best decision for your specific needs. Here are a few common types
            of Student Visas.<br></br>
            1. Full-time Student Visa: This type of visa is for students who
            plan to take up full-time studies in an institution. It typically
            allows you to stay in the country for the duration of
            <br></br> your program, and work part-time during your studies.
            <br></br>
            2. Exchange/short-term Student Visa: As the name suggests, this type
            of visa is for students who are participating in an exchange program
            or a short-term program. <br></br>This type of visa usually has
            stricter conditions and a shorter validity period. <br></br>
            3. Research Student Visa: This type of visa is for students who are
            conducting research as part of their studies. This visa is often
            valid for a longer period of time than a standard <br></br>
            Student Visa. It may also allow you to work part-time or take on
            paid or unpaid internships.
          </p>
          <h2 className="whatt">Eligibility criteria for Student Visa</h2>
          <p className="what">
            In general, you must fulfil the following requirements to study in
            order to be eligible for a Student Visa. Specifics can vary from
            country to country.<br></br> 1. Being accepted at a recognised
            institution: This means that you need to have a letter of acceptance
            from a school or university that is authorised to accept
            international students.<br></br>
            2. Prove that you have sufficient funds: This may include showing
            bank statements or other financial documents to demonstrate that you
            have enough money to cover your living expenses <br></br>while
            you're studying.<br></br>
            3. Having a valid passport: This is obviously a requirement for any
            kind of international travel, and a Student Visa is no exception.
            <br></br>
            4. Passing a medical examination: Depending on the country, you may
            be required to undergo a medical exam to ensure you're in good
            health before you're granted a Student Visa.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Studyvisa;
