import React from "react";

const AdminAuthRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const isAdmin = user?.admin?.isAdmin ? true : false;
  if (!isAdmin) {
    return <h1 >Access Denied, Admin only</h1>;
  }

  return <>{children}</>;
};

export default AdminAuthRoute;
