import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/admin/Login";
import AdminDashBoard from "./components/admin/AdminDashBoard";
import AdminAuthRoute from "./components/admin/adminAuthRoute/AdminAuthRoute";
import Home from "./frontEndPages/Home";
import Navbar from "./frontEndPages/Navbar";
import About from "./frontEndPages/About";
import ContactUs from "./components/ContactUs";
import Footer from "./frontEndPages/Footer";
import Studyvisa from "./frontEndPages/ourVisa/Studyvisa";
import Touristvisa from "./frontEndPages/ourVisa/Touristvisa";
import Spousevisa from "./frontEndPages/ourVisa/Spousevisa";
import Immigrationvisa from "./frontEndPages/ourVisa/Immigrationvisa";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/contactUS" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          {/* nested route */}
          <Route
            path="/adminDashboard"
            element={
              <AdminAuthRoute>
                <AdminDashBoard />
              </AdminAuthRoute>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/studyVisa" element={<Studyvisa/>} />
          <Route path="/touristVisa" element={<Touristvisa/>} />
          <Route path="/spouseVisa" element={<Spousevisa/>} />
          <Route path="/immigrationVisa" element={<Immigrationvisa/>} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
