import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getAllData } from "../../redux/slices/UserdataSlice";
import { Link } from "react-router-dom";
import baseURL from "../../utils/baseURL";
import axios from "axios";

const AdminDashBoard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const { userAllData, error, loading } = useSelector(
    (state) => state?.userData?.userAllData
  );

  function userDelete(_id) {
    let ans = window.confirm("Want To Delete Data ?");
    if (!ans) return;

    axios
      .delete(`${baseURL}/deleteUser/`, { data: { _id: _id } })
      .then((d) => {
        window.location.reload();
      })
      .catch(() => {
        alert("Unable To Delete Data !!!");
      });
  }
  //console.log(userAllData);
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      Admin DashBoard
                    </h1>
                    <div class="row">
                      <div class="col-12 col-xxl-8">
                        <p class="fs-5 mb-0 text-secondary">
                          Saara Data Niche Hai
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../../../images/AdminDashboard.webp"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      <div className="container-fluid mt-9" style={{ marginTop: "250px" }}>
        <div className="border m-2 p-2">
          <table className="table table-bordered table-stripped table-hover">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Visa Type</th>
                <th>Message</th>
                <th>Delete</th>
              </tr>
            </thead>

            <tbody>
              {userAllData?.map((data) => {
                console.log(data);
                return (
                  <tr>
                    <td>{data?.firstName}</td>
                    <td>{data?.lastName}</td>
                    <td>{data?.email}</td>
                    <td>{data?.phoneNumber}</td>
                    <td>{data?.visaType}</td>
                    <td>{data?.message}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          userDelete(data?._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashBoard;
