import React from "react";
import "../../src/palette-bsb.css";

const About = () => {
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      About Us
                    </h1>
                    <div class="row">
                      <div class="col-12 col-xxl-8">
                        <p
                          class="fs-5 mb-0 text-secondary "
                          style={{ marginLeft: "160px" }}
                        >
                          Our team can assist you in transforming your business
                          through Latest Tech capabilities to stay ahead of the
                          curvet. Our team Can assist you in transforming your
                          business through.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../../../images/frontEndImg/headerimgs/about-img-1.webp"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <section class="bg-primary" data-bs-theme="dark">
        <div class="container-fluid overflow-hidden">
          <div class="row">
            <div class="col-12 col-md-6 p-0">
              <img
                class="img-fluid w-100 h-100 object-fit-cover"
                loading="lazy"
                src="../images/111.webp"
                alt=""
              />
            </div>
            <div class="col-12 col-md-6 align-self-md-end">
              <div class="row py-3 py-sm-5 py-xxl-9 bsb-section-mt-md-1 justify-content-sm-center">
                <div class="col-12 col-sm-10">
                  <h4 class="mb-5">Get To Know Us</h4>
                  <h2 class="display-4 fw-bold mb-4">
                    Welcome To Swastik Abroad Consultants
                  </h2>
                  <p class="fs-5 mb-3 text-secondary">
                    Swastik Abroad Consultant is one of the best immigration
                    consultants. We helped a lot of families to get settled down
                    abroad. We design our services in a way that our clients get
                    full information about the immigration process. Then it will
                    help them to decide what type of visa they want.
                  </p>
                  <p>
                    We try to give a possible way to get a PR visa or any other
                    visa for our clients. Our main aim is to fulfill the needs
                    and desires of clients
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Section - Services -->
    <!-- Service 4 - Bootstrap Brain Component --> */}
        <section class="py-5 py-xl-8 bsb-section-py-xxl-1">
          <div class="container mb-5 mb-md-6 mb-xl-10">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h3 class="fs-6 text-secondary mb-2 text-uppercase"></h3>
                <h2 class="display-4 fw-bold mb-0">
                  Why Choose Swastik Abroad Consultant
                </h2>
              </div>
            </div>
          </div>

          <div class="container overflow-hidden">
            <div class="row gy-4 gy-xxl-5 gx-xxl-5">
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <i
                      aria-hidden="true"
                      class="elementkit-infobox-icon icon icon-tick"
                    ></i>
                    <h4 class="fw-bold mb-4">Personalized Guidance</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h4 class="fw-bold mb-4">Legal Expertise</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <div>
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <h4 class="fw-bold mb-4">Guidance Documentation</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h4 class="fw-bold mb-4">Interview Training</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h4 class="fw-bold mb-4">Complimentary Courses</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h4 class="fw-bold mb-4">Connect To Global Network</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section - Team -->
    <!-- Team 2 - Bootstrap Brain Component --> */}
      </main>
    </div>
  );
};

export default About;
