import React from "react";

const Immigrationvisa = () => {
  return (
    <>
      <div>
        <header id="header">
          {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
          <section class="bg-primary" data-bs-theme="dark">
            <div class="container-fluid overflow-hidden">
              <div class="row">
                <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                  <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                    <div class="col-12 col-sm-10">
                      <h1 class="display-2 fw-bolder mb-4 text-white">
                        Welcome to Swastik Abroad Consultants
                      </h1>
                      <h2> Immigration Consulting Services</h2>

                      <div class="row">
                        <div class="col-12 col-xxl-8"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 p-0">
                  <figure
                    class="bsb-overlay-figure w-100 h-100 m-0"
                    style={{ opacity: "9" }}
                  >
                    <img
                      class="img-fluid w-100 h-100 object-fit-cover"
                      loading="lazy"
                      src="../images/study visa/imigration.jpg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
      <div className="mt-7">
        <h2 className="imig">Our Sevices</h2>
        <p>
          <b>Visa Consultation:</b> Whether you're applying for a work visa,
          student visa, tourist visa, or any other
          <br />
          type of visa, our experienced consultants will provide personalized
          guidance tailored to your
          <br />
          specific circumstances.
        </p>
        <p>
          <b>Permanent Residency:</b> We assist individuals and families seeking
          to obtain permanent residency
          <br />
          in [Your Country]. From assessing eligibility to preparing and
          submitting applications, we'll be
          <br />
          with you every step of the way.
        </p>
        <p>
          <b>Citizenship Applications:</b> If you're ready to take the next step
          and become a citizen of [Your Country],
          <br />
          we'll help you navigate the citizenship application process with ease
          and confidence.
        </p>
        <p>
          <b>Family Sponsorship:</b> Reuniting with loved ones is a priority for
          many immigrants. Our team can
          <br />
          assist you in sponsoring family members for immigration to [Your
          Country], ensuring that you
          <br />
          meet all requirements and deadlines.
        </p>
        <p>
          <b>Business Immigration:</b> For entrepreneurs, investors, and
          business professionals looking to
          <br />
          establish or expand their presence in [Your Country], we offer expert
          guidance on business
          <br />
          immigration options and requirements.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="imig">Why Choose Swastik Abroad Consultants?</h2>
        <p>
          <b>Student counseling:</b> Personalized guidance for students.
        </p>
        <p>
          <b>Visa filing assistance:</b> Navigating the documentation process.
        </p>
        <p>
          <b>Profile assessment:</b> Ensuring tailored advice.
        </p>
        <p>
          <b>Top-quality services:</b> Commitment to excellence.
        </p>
        <p>
          <b>Affordable price:</b> Quality assistance without breaking the bank.
        </p>
        <p>
          <b>Great outreach:</b> Serving a global clientele.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="imig">Services Offered</h2>
        <p>1. Study visa</p>
        <p>2. Visitor permit</p>
        <p>3. Dependent visa</p>
        <p>4. Pre-departure services</p>
        <p>5. Post-landing services</p>
      </div>
    </>
  );
};

export default Immigrationvisa;
