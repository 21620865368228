import React from "react";
import "../../src/palette-bsb.css";
import { useNavigate } from "react-router-dom";
import ContactUs from "../components/ContactUs";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      We are best Consultants
                    </h1>
                    <div class="row">
                      <div class="col-12 col-xxl-8">
                        <p class="fs-5 mb-5 text-secondary">
                          We work in a customized way by devising and handling
                          entirely online projects with long-term partnerships.
                        </p>
                      </div>
                    </div>
                    <div class="d-grid gap-2 d-sm-flex">
                      {/* <a className="btn btn-success" href="/contactUs">Contact Now</a> */}
                      <button
                        type="button"
                        class="btn btn-accent bsb-btn-2xl rounded-pill"
                        onClick={() => navigate("/contactUS")}
                      >
                        Contact Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../../../images/frontEndImg/headerimgs/hero-img-1.webp"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      <main id="main">
        {/* <!-- Section - Services -->
<!-- Service 4 - Bootstrap Brain Component --> */}
        <section class="py-5 py-xl-8 bsb-section-py-xxl-1">
          <div class="container mb-5 mb-md-6 mb-xl-10">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h3 class="fs-6 text-secondary mb-2 text-uppercase">
                  What We Do?
                </h3>
                <h2 class="display-4 fw-bold mb-0">
                  We are proud of our expert services.
                </h2>
              </div>
            </div>
          </div>

          <div class="container overflow-hidden">
            <div class="row gy-4 gy-md-0 gx-xxl-5">
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <img className="passport" src="../images/passport.png" />
                    <h4 class="fw-bold mb-4">Apply Online Visa</h4>
                    <p class="mb-4 text-secondary">
                      We are reliable immigration consultants to handle your
                      immigration case.
                    </p>
                    <a
                      href="#!"
                      class="fw-bold text-decoration-none link-primary"
                    >
                      Learn More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <img className="book" src="../images/book.jpg" />
                    <h4 class="fw-bold mb-4">Book An Appointment</h4>
                    <p class="mb-4 text-secondary">
                      To process your visa application with our experienced
                      registered agents.
                    </p>
                    <a
                      href="#!"
                      class="fw-bold text-decoration-none link-primary"
                    >
                      Learn More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <img className="book" src="../images/imigration.png" />
                    <h4 class="fw-bold mb-4">Immigration Experts</h4>
                    <p class="mb-4 text-secondary">
                      Our goal has been provide immigration in all over country
                      and universities.
                    </p>
                    <a
                      href="#!"
                      class="fw-bold text-decoration-none link-primary"
                    >
                      Learn More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Section - About -->
<!-- About 1 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 p-0">
                <img
                  class="img-fluid w-100 h-100 object-fit-cover"
                  loading="lazy"
                  src="../images/111.webp"
                  alt=""
                />
              </div>
              <div class="col-12 col-md-6 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xxl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h4 class="mb-5">Get To Know Us</h4>
                    <h2 class="display-4 fw-bold mb-4">
                      Welcome To Swastik Abroad Consultants
                    </h2>
                    <p class="fs-5 mb-3 text-secondary">
                      Swastik Abroad Consultant is one of the best immigration
                      consultants. We helped a lot of families to get settled
                      down abroad. We design our services in a way that our
                      clients get full information about the immigration
                      process. Then it will help them to decide what type of
                      visa they want.
                    </p>
                    <p>
                      We try to give a possible way to get a PR visa or any
                      other visa for our clients. Our main aim is to fulfill the
                      needs and desires of clients
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Section - Testimonials -->
<!-- Testimonial 3 - Bootstrap Brain Component --> */}
        <section class="py-5 py-xl-8 bsb-section-py-xxl-1">
          <div class="container mb-5 mb-md-6 mb-xl-10">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-10 col-lg-8 col-xl-7 text-center">
                <h3 class="fs-6 text-secondary mb-2 text-uppercase">
                  Happy Customers
                </h3>
                <h2 class="display-4 fw-bold mb-0">
                  We believe in the client's happiness.
                </h2>
              </div>
            </div>
          </div>

          <div class="container overflow-hidden">
            <div class="row gy-4 gy-md-0 gx-xxl-5">
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body p-4 p-xxl-5">
                    <figure>
                      <img
                        class="img-fluid rounded rounded-circle mb-4 border border-5"
                        loading="lazy"
                        src="../images/study visa/studyvisa.jpg"
                        alt="Luna John"
                      />
                      <figcaption>
                        <blockquote class="bsb-blockquote-icon mb-4">
                          A student visa is a government-issued immigration
                          document granted by a consulate general or embassy,
                          which allows yo u to legally study in a specific
                          country for a specific date range.
                        </blockquote>
                        <h4 class="mb-2">Study Visa</h4>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body p-4 p-xxl-5">
                    <figure>
                      <img
                        class="img-fluid rounded rounded-circle mb-4 border border-5"
                        loading="lazy"
                        src="../images/study visa/TouristVisa.jpg"
                        alt="Mark Smith"
                      />
                      <figcaption>
                        <blockquote class="bsb-blockquote-icon mb-4">
                          Tourist/Travel visas allow the bearer to enter a
                          foreign country for touristic and leisure purposes
                          only and stay for a predetermined amount of time.
                          These visas do not entitle the holder to work or
                          engage in any business activities in the host country.
                        </blockquote>
                        <h4 class="mb-2">Tourist Visa</h4>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card bsb-tpl-bg-lotion bsb-tpl-border-lg-cyan">
                  <div class="card-body p-4 p-xxl-5">
                    <figure>
                      <img
                        class="img-fluid rounded rounded-circle mb-4 border border-5"
                        loading="lazy"
                        src="../images/flag/spouse.jpg"
                        alt="Luke Reeves"
                      />
                      <figcaption>
                        <blockquote class="bsb-blockquote-icon mb-4">
                          To be eligible for an Indian spouse visa, the
                          applicant must meet the following criteria: Marriage:
                          The applicant must be legally married to an Indian
                          citizen or a person of Indian origin who holds an
                          Indian passport. The marriage must be recognised as
                          legal in the country where it took place.
                        </blockquote>
                        <h4 class="mb-2">Spouse Visa</h4>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        {/* <!-- Section - Clients -->
<!-- Client 2 - Bootstrap Brain Component --> */}
        <section class="py-5 py-xl-8 bsb-section-py-xxl-1">
          <div class="container mb-5 mb-md-6 mb-xl-10">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h3 class="fs-6 text-secondary mb-2 text-uppercase">
                  Our Clients
                </h3>
                <h2 class="display-4 fw-bold mb-0">
                  Trusted by over 786+ esteemed clients
                </h2>
              </div>
            </div>
          </div>

          <div class="container overflow-hidden">
            <div class="row gy-4">
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/canada.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/australia.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/america.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/uk.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/switzerland.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/itsly.webp" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/japan.jpg" />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3 text-center">
                <div class="text-secondary bsb-tpl-bg-lotion border bsb-tpl-border-lg-cyan px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                  <img className="flag" src="../images/flag/france.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
