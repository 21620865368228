import React from "react";

const Spousevisa = () => {
  return (
    <div>
      <header id="header">
        {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
        <section class="bg-primary" data-bs-theme="dark">
          <div class="container-fluid overflow-hidden">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                  <div class="col-12 col-sm-10">
                    <h1 class="display-2 fw-bolder mb-4 text-white">
                      Welcome to Swastik Abroad Consultants
                    </h1>
                    <h2> Spouse Visa Consulting Services</h2>
                    <div class="row">
                      <div class="col-12 col-xxl-8"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-0">
                <figure
                  class="bsb-overlay-figure w-100 h-100 m-0"
                  style={{ opacity: ".9" }}
                >
                  <img
                    class="img-fluid w-100 h-100 object-fit-cover"
                    loading="lazy"
                    src="../images/flag/spouse visa.webp"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </header>
      <div className="mt-8">
        <h1 className="tourist">
          There Are Two Types of Dependent Visas (Temporary and permanent)
        </h1>
        <p>
          1. Temporary Visas for holders of the following visas
          <br />- Work, intra-company transfer, Student, Fiancé
        </p>
        <p>
          Permanent Visas are for spouses or children below the age of 18-21
          years old
          <br />- Immigration dependents
        </p>
        <p>
          Spouses/partners on temporary Dependent Visas are allowed limited work
          rights based on their visa validity in most countries, except the US.
        </p>
        <p>
          Dependents granted Permanent Residence Visas have the right to live,
          study, and work for as long as they remain permanent residents.
        </p>
        <h4 className="tourist">What is a dependent certificate?</h4>
        <p>
          This certificate establishes the dependent status of the citizen for
          all legal and official purposes. It is a record provided to the
          citizen of any country. It is provided by that
          <br />
          nation’s government approving and affirming that a person is a
          dependent. Dependents also mean persons who are not themselves earners
          but depend on a person – be
          <br />
          it a spouse, parent, or any other close relative, for food, shelter,
          and all other basic needs. If you get a dependent certificate, you can
          apply for a dependent visa in a<br />
          country where the family’s main breadwinner is living.{" "}
        </p>
        <p>
          In India, you can prove you are a dependent by obtaining a birth
          certificate and proof of identity, such as an Aadhar Card, a valid
          passport, or a driving license.
        </p>
      </div>
      <div className="mt-8">
        <h1 className="tourist">Dependent Visa Details:</h1>
        <p>
          To reunite families, countries across the world offer dependent visas
          with various facilities. Typically, these visas allow you to call your
          immediate family abroad through a<br />
          short process that focuses extensively on your financial ability.
          Usually, dependent visas allow successful applicants to:
        </p>
        <p>
          Stay in the country they are applying to under the sponsorship of
          their relative
          <br />
          Work or study in some cases
          <br />
          Travel in that country
          <br />
        </p>
      </div>
      <div className="mt-8">
        <div className="mt-8">
          <h1 className="tourist">Steps to apply for a Dependent visa</h1>
          <p>
            Step 1: Gather all the necessary documents.
            <br />
            Step 2: Fill out the application form completely
            <br />
            Step 3: Pay the required visa fees.
            <br />
            Step 4: Visit the nearest embassy
            <br />
            Step 5: Get all the documents verified from the embassy
            <br />
            Step 6: If you fulfill all the requirements, you will get a
            dependent visa.
            <br />
          </p>
        </div>
        <div className="mt-8">
          <h1 className="tourist">Benefits of a Dependent visa</h1>
          <p>
            Ability to work and study
            <br />
            Your children will be eligible to attend private schools or state
            schools
            <br />
            Can be with family
            <br />
            Can bring their spouse, civil partner, or unmarried partner with
            them
            <br />
            Can take up employment opportunity
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Spousevisa;
