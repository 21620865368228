import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseURL from "../../utils/baseURL";
import axios from "axios";
import {
  resetErrorAction,
  resetSuccessAction,
} from "../globalActions/globalAction";

//initialState
const initialState = {
  userAllData: [],
  userSingleData: {},
  error: null,
  loading: null,
  isAdded: false,
  isUpdated: false,
  isDelete: false,
};

export const createUserDataAction = createAsyncThunk(
  "product",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    console.log(payload);
    try {
      const { firstName, lastName, phoneNumber, visaType, email, message } =
        payload;

      //make Request
      const { data } = await axios.post(`${baseURL}/userCreate`, {
        firstName,
        lastName,
        phoneNumber,
        visaType,
        email,
        message,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllData = createAsyncThunk(
  "/getAllData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseURL}/getusers`);
      //console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// export const deleteUser = createAsyncThunk(
//   async (userId, { rejectWithValue, getState, dispatch }) => {
//     try {
//       await axios.delete(`${baseURL}/userDelete/${userId}`);
//     } catch (error) {
//       return rejectWithValue(error?.response?.data);
//     }
//   }
// );

//create slice Admin
const userDataSlice = createSlice({
  name: "userData",
  initialState,
  extraReducers: (builder) => {
    //create
    builder.addCase(createUserDataAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserDataAction.fulfilled, (state, action) => {
      state.userSingleData = action.payload;
      state.isAdded = true;
      state.loading = false;
    });

    builder.addCase(createUserDataAction.rejected, (state, action) => {
      state.userSingleData = null;
      state.isAdded = false;
      state.loading = false;
      state.error = action.payload;
    });

    //get all Data
    builder.addCase(getAllData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllData.fulfilled, (state, action) => {
      state.userAllData = action.payload;
      state.isAdded = true;
      state.loading = false;
    });
    builder.addCase(getAllData.rejected, (state, action) => {
      state.userAllData = null;
      state.isAdded = false;
      state.error = action.payload;
      state.loading = false;
    });

    // //delete Data
    // builder.addCase(deleteUser.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(deleteUser.fulfilled, (state, action) => {
    //   state.userSingleData = action.payload;
    //   state.isDelete = true;
    //   state.loading = false;
    // });
    // builder.addCase(deleteUser.rejected, (state, action) => {
    //   state.userSingleData = null;
    //   state.isDelete = false;
    //   state.error = action.payload;
    //   state.loading = false;
    // });

    // reset success
    builder.addCase(resetSuccessAction.pending, (state, action) => {
      state.isAdded = false;
    });
    //reset error Action
    builder.addCase(resetErrorAction.pending, (state, action) => {
      state.error = null;
    });
  },
});

// generate the reducer

const userDataReducer = userDataSlice.reducer;

export default userDataReducer;
