import { configureStore } from "@reduxjs/toolkit";
import userDataReducer from "../slices/UserdataSlice";
import adminLoginReducer from "../loginUser/LoginUser";

const store = configureStore({
  reducer: {
    userData : userDataReducer,
    adminsLogin :adminLoginReducer
  },
});

export default store;
