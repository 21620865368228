import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { resetSuccessAction } from "../redux/globalActions/globalAction";


const SuccessMsg = ({ message }) => {
  const dispatch = useDispatch()
    Swal.fire({
      icon: "success",
      title: "Save Data", 
      text: message,
      confirmButtonText: "ok",
    });
    dispatch(resetSuccessAction())
  };

export default SuccessMsg;
