import React from "react";
import "../../css/touristvisa.css";
const Touristvisa = () => {
  return (
    <div>
      <div>
        <header id="header">
          {/* <!-- Hero 4 - Bootstrap Brain Component --> */}
          <section class="bg-primary" data-bs-theme="dark">
            <div class="container-fluid overflow-hidden">
              <div class="row">
                <div class="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
                  <div class="row py-3 py-sm-5 py-xl-9 bsb-section-mt-md-1 justify-content-sm-center">
                    <div class="col-12 col-sm-10">
                      <h1 class="display-2 fw-bolder mb-4 text-white">
                        Welcome to Swastik Abroad Consultants
                      </h1>
                      <h2>Tourist Consulting Services</h2>
                      <div class="row">
                        <div class="col-12 col-xxl-8"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 p-0">
                  <figure
                    class="bsb-overlay-figure w-100 h-100 m-0"
                    style={{ opacity: ".9" }}
                  >
                    <img
                      class="img-fluid w-100 h-100 object-fit-cover"
                      loading="lazy"
                      src="../images/study visa/TouristVisa.jpg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
      {/* main content */}
      <div className="mt-10">
        <h2 className="tourist">What Is a Tourist Visa?</h2>
        <p>
          Tourist visas are official travel documents issued to foreign visitors
          for leisure purposes. For example, if you
          <br /> are from India and want to visit the US, you need to apply for
          a tourist visa. Every country regulates its visa
          <br /> policy for tourists, and their corresponding embassies and
          consulates will help you obtain a visa for short
          <br />
          -term visits.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="tourist">Tourist Visa Guidelines</h2>
        <p>
          While you are visiting a foreign country under a tourist visa, you
          must keep in mind the following rules
        </p>
        <p>
          You cannot work with a tourist visa.
          <br />
          You cannot do business under a tourist visa.
          <br />
          You cannot study while you have a tourist visa.
          <br />
          You cannot become a permanent resident with a tourist visa.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="tourist">Assemble the documents required</h2>
        <p>
          To submit a successful tourist visa application, you need to prepare
          your documents.
          <br />
          If your documents are only in your native language, you must translate
          them as per the consulate’s instructions. Plus, some
          <br />
          civil documents such as birth certificates, marriage certificates, and
          so on may require
        </p>
      </div>
      <div className="mt-8">
        <h2 className="tourist">Pay the required tourist visa fee</h2>
        <p>
          To have your application processed, you need to pay the required visa
          fee— the amount of which varies
          <br />
          depending on your destination. Some embassies require you to pay the
          fee before attending the visa
          <br />
          interview, while others may ask to pay the same day.
        </p>
      </div>
    </div>
  );
};

export default Touristvisa;
