import { createAsyncThunk } from "@reduxjs/toolkit";

//reset error action 

export const resetErrorAction = createAsyncThunk('/resetErr-Action',()=>{
    return {}
});

// reset success Action

export const resetSuccessAction = createAsyncThunk('/resetSucc-Action' , ()=>{
    return {}
})